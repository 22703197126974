@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@font-face {
  font-family: "Athletics Bold";
  src: url("./assets/fonts/Athletics-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Athletics Light";
  src: url("./assets/fonts/Athletics-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Athletics Medium";
  src: url("./assets/fonts/Athletics-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Athletics Regular";
  src: url("./assets/fonts/Athletics-Regular.ttf");
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter";
  font-weight: 400;
  background-color: #fffbf7;
  max-height: 100%;
  width: 100%;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

select {
  background-color: transparent;
  color: #000;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
