.paymentSuccessfulContainer {
  height: 478.07px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.paymentSuccessfulContainer .message {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  margin: 11.32px unset unset unset;
  text-align: center;
}
