/* input styles */
.form {
  background: #ffffff;
}

.form form {
  /* padding: 29.03px 18.23px 42.78px 18.23px; */
  padding: 29.03px 17.82px 20px 17.82px;
}

.form .formSubHead {
  background-color: rgba(221, 221, 221, 0.6);
  color: black;
  max-height: 44px;
  padding: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.form .formSubHead p {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.01em;
  font-feature-settings: "ss05" on, "ss06" on, "liga" off;
  color: #000000;
  margin: unset;
  width: 198px;
}

.form .formSubHead span {
  font-weight: 600;
}

.formInputContainer,
.formSelectContainer {
  margin-bottom: 29.03px;
}

.formInput,
.formSelectInput {
  width: 100%;
  height: 100%;
  padding: 12.82px 13.13px;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.02em;
  position: relative;
  border: 0.820643px solid #cccccc;
  border-radius: 6.56514px;
}

.formInput:focus-within,
.formSelectInput:focus-within {
  border: 1px solid black;
  border-radius: 6.56514px;
}

.formInputContainer .errorMessage {
  color: #ef2c5a;
  font-size: 8px;
  margin: 0;
}

.formInput::before,
.formSelectInput::before {
  content: attr(data-content);
  position: absolute;
  top: -7.22px;
  left: 12.82px;
  z-index: 1;
  background-color: #fff;
  font-size: 9.84772px;
  line-height: 13px;
  letter-spacing: -0.02em;
  font-feature-settings: "ss05" on, "ss06" on, "liga" off;

  color: #cfcdcd;
  font-feature-settings: "ss05" on, "ss06" on, "liga" off;
  color: #717070;
  padding: 0px 1.64px;
}

.formInput input {
  width: 100%;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.02em;
  font-feature-settings: "ss05" on, "ss06" on, "liga" off;
  color: #000000;
  border: unset;
  outline: none;
  background-color: transparent;
  font-family: "Inter";
}

.formSelectInput select::-ms-expand {
  display: none;
}

.formSelectInput select {
  width: 100%;
  height: 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  position: relative;
  font-family: "Inter";
  font-size: 14px;
  line-height: 13px;
}

.formSelectInput select.selectInputField {
  background-image: url("../assets/icons/dropdown.svg");
  background-repeat: no-repeat;
  background-position: center right;
  font-family: "Inter";
  font-size: 14px;
  line-height: 13px;
}

.formInput input :focus {
  border: unset;
}

.formInput select {
  width: 100%;
  border: 1.5px solid white;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-family: "Inter";
  font-size: 14px;
  line-height: 13px;
}

.formInput select.selectInputField {
  background-image: url("../assets/icons/dropdown.svg");
  background-repeat: no-repeat;
  background-position: center right;
}

/* form style */

.formBody .formDiv {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 13.11px;
  font-family: "Inter";
  font-size: 14px;
  line-height: 13px;
}

.formDivDisabled .formInput input,
.formDivDisabled .formInput::before {
  color: #cfcdcd;
}

.formBody .payBtn {
  background: #ef2c5a;
  border-radius: 6.56514px;
  padding: 10.43px 10px;
  border: none;
  outline: none;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  font-family: Athletics Medium;
  letter-spacing: -0.02em;
  font-feature-settings: "ss05" on, "ss06" on, "liga" off;
  color: #ffffff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.formBody .payBtn .payBtnLogo {
  width: 49px;
  height: 17px;
  margin-left: 3px;
}

.formBody .cancelBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  height: 39.98px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  color: #000;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  font-feature-settings: "cv04" on, "cv03" on, "liga" off;
  width: 100%;
  padding: 14px 24px;
  margin-top: 16px;
  font-style: normal;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  line-height: 20px;
}
.formBody .payBtn:disabled {
  background: #f0a8b9;
  cursor: not-allowed;
}
