/* .container {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 130px 0px;
  background-color: #fffbf7;
}

.container .body {
  min-width: 304px;
  max-width: 304px;
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e2e2e2;
}

.container .body .header {
  border-bottom: 1px solid #f5f5f5;
  height: 83.76px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 18.25px;
}

.container .body .footer {
  border-top: 1px solid #f5f5f5;
  height: 50.13px;
  padding: 20px 18.25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 8px;
  line-height: 10px;
}

.container .body .footer p,
.container .body .footer p a {
  margin: unset;
  color: #a3a3a3;
}

.container .body p a {
  text-decoration: none;
}

.body .loaderContainer {
  background: #fffbf7;
  z-index: 99999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.body .apploaderContainer {
  background: rgba(255, 251, 247, 0.5);
  z-index: 99999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.body .loaderContainer .spin,
.body .apploaderContainer .spin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body .loaderContainer .spinner,
.body .apploaderContainer .spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 1px solid #ef2c5a;
  border-radius: 50%;
  padding: 39px;
  animation: spinner 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ef2c5a rgba(239, 44, 90, 0.6) rgba(239, 44, 90, 0.3)
    rgba(239, 44, 90, 0.1);
  z-index: 99999999999999999999999999999;
}

.body .loaderContainer .spinner,
.body .apploaderContainer .spinner {
  padding: 80px;
}

.body .loaderContainer .spinner,
.body .apploaderContainer .spinner {
  animation-delay: -0.45s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */

.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fffbf7;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  overflow-y: auto;
}

.container .body {
  min-width: 304px;
  max-width: 304px;
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #e2e2e2;
  /* min-height: 663.98px; */
}

.container .body .header {
  border-bottom: 1px solid #f5f5f5;
  height: 83.76px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* padding: 32px 18px 32.16px 18px; */
  padding: 32.24px 16.34px 31.97px 16.34px;
}

.container .body .footer {
  border-top: 1px solid #f5f5f5;
  /* height: 50.13px; */
  height: 74.73px;
  /* padding: 20px 18px 20.13px 18.2px; */
  padding: 32.49px 18px 32.24px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 8px;
  line-height: 10px;
}

.container .body .footer p,
.container .body .footer p a {
  margin: unset;
  color: #a3a3a3;
}

.container .body p a {
  text-decoration: none;
}

.body .loaderContainer {
  background: #fffbf7;
  z-index: 99999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.body .apploaderContainer {
  background: rgba(255, 251, 247, 0.5);
  z-index: 99999999 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.body .loaderContainer .spin,
.body .apploaderContainer .spin {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.body .loaderContainer .spinner,
.body .apploaderContainer .spinner {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 1px solid #ef2c5a;
  border-radius: 50%;
  padding: 39px;
  animation: spinner 0.9s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ef2c5a rgba(239, 44, 90, 0.6) rgba(239, 44, 90, 0.3)
    rgba(239, 44, 90, 0.1);
  z-index: 99999999999999999999999999999;
}

.body .loaderContainer .spinner,
.body .apploaderContainer .spinner {
  width: 124px;
  height: 124px;
}

.body .loaderContainer .spinner,
.body .apploaderContainer .spinner {
  animation-delay: -0.45s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 280px) {
  .container .body {
    min-width: 280px;
    max-width: 280px;
  }
}
